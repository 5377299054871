import React from "react";

const Footer = (props) => {
  return (
    <div className="footer">
      <p> &copy; Designed & built by Annika Engøy 2023</p>
    </div>
  );
};

export default Footer;
