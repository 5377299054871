import React from "react";

// React Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";


// Images
import Jon from "../assets/images/JonNeeraas.jpg";
import Eirin from "../assets/images/Eirin.jpg";
import Malin from "../assets/images/Malin.jpg";
import Silje from "../assets/images/Silje.jpg";
import Lasse from "../assets/images/Lasse.jpg";

// React Icons
import { IconContext } from "react-icons";
import { FiGithub, FiLinkedin } from "react-icons/fi";

const Portfolio = (props) => {
  return (
    <>
      <Container className="portfolio_container">
        <IconContext.Provider
          value={{
            color: "white",
            size: "2em",
            className: "react_icon",
          }}
        >
          <Row className="portfolio-row justify-content-center">

            <h1>Styret</h1>
            <Col className="cards" md={6} lg={4}>
              <Card>
                <Card.Img className="card-img" variant="top" src={Jon} />
                <Card.Body>
                  <Card.Title>Jon Neraas</Card.Title>
                  <p className="sub-title">Daglig leder</p>
                  <Card.Text>
                    <p>

                       Jon har 30 års erfaring med ulike teknologiselskaper nasjonalt og internasjonalt. Han har sin utdannelse innen økonomi og finans, men har i sitt virke hatt hovedfokus på salg og strategiutvikling. Jon har bred erfaring fra de nordiske land, Europa og USA med ulike lederstillinger og styreverv. I dag jobber Jon operativt som Executive Vice President i det børsnoterte selskapet Austria Card Holdings, som han selv har vært med å bygge opp.
                    </p>
                 <a
                    className="card-link"
                    href="https://www.linkedin.com/in/jon-neeraas-5884417a/"
                  > 
                      <FiLinkedin/> 
                  </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="cards" md={6} lg={4}>
              <Card>
                <Card.Img className="card-img" variant="top" src={Eirin} />
                <Card.Body>
                  <Card.Title>Eirin Moe</Card.Title>
                                    <p className="sub-title">Styremedlem</p>

                  <Card.Text>
                  Eirin er utdannet økonom og har 30 års erfaring fra både mindre selskaper og større konsern. I tillegg til hennes yrkesutøvelse innen regnskap har hun aktivt engasjert seg i ulike styreverv. I dag jobber Eirin operativt med regnskapsføring for flere internasjonale selskaper.
                  </Card.Text>
                  <a
                    className="card-link"
                    href="https://www.linkedin.com/in/eirin-helen-moe-b90428100/"
                  > 
                      <FiLinkedin/> 
                  </a>
                </Card.Body>
              </Card>
            </Col>

 <Col className="cards"  md={6} lg={4}>
              <Card>
                <Card.Img className="card-img" variant="top" src={Malin} />
                <Card.Body>
                  <Card.Title>Malin Moe Neraas</Card.Title>
                                                      <p className="sub-title">Styremedlem</p>

                  <Card.Text>
                  Med en akademisk bakgrunn fra Handelshøyskolen BI, utdannelse som helseveileder og et gründermindset har Malin formet karrieren sin til å være en blanding av hennes to favorittverdener; forretningsutvikling og helse. Malin har seks års erfaring som gründer, og har sin spisskompetanse innen konseptutvikling. De siste årene har hun jobbet som Markedsdirektør i ABEL Technologies AS, hvor hun nylig har tatt steget videre inn i rollen som Operasjonsdirektør for å videreutvikle operasjonelle prosesser og øke strukturkapitalen i selskapet. Malin er også engasjert i andre helseselskaper med flere styreverv.
                  </Card.Text>
                   <a
                    className="card-link"
                    href="https://www.linkedin.com/in/malin-moe-neeraas-3b75ba1a1/"
                  > 
                      <FiLinkedin/> 
                  </a>
                </Card.Body>
              </Card>
            </Col>

                      <Col className="cards"   md={6} lg={4}>
              <Card>
                <Card.Img className="card-img" variant="top" src={Silje} />
                <Card.Body>
                  <Card.Title>Silje Moe Neraas</Card.Title>
                                                      <p className="sub-title">Styremedlem</p>

                  <Card.Text>
                  Silje har en bachelorgrad i forretningsjus og økonomi fra Handelshøyskolen BI, med spisskompetanse innen avtalerett og kjøpsrett. Silje setter menneskelige relasjoner høyt og er kjent for sin evne til å håndtere detaljer. Utover hennes yrkesutøvelse innen forretningsjuss har Silje en interesse og erfaring innen markedsføring og innholdsproduksjon.
                  </Card.Text>
                    <a
                    className="card-link"
                    href="https://www.linkedin.com/in/silje-moe-neeraas-a2a979212/"
                  > 
                      <FiLinkedin/> 
                  </a>
                </Card.Body>
              </Card>
            </Col>
            <Col className="cards" md={6} lg={4}>
              <Card>
                <Card.Img className="card-img" variant="top" src={Lasse} />
                <Card.Body>
                  <Card.Title>Lasse Moe Neraas</Card.Title>
                                                      <p className="sub-title">Styremedlem</p>

                  <Card.Text>
                  Lasse er vårt nyeste tilskudd i styret. Lasse er utdannet mekaniker, og er nå under utdanning ved Handelshøyskolen BI, der han studerer økonomi. Lasses tilstedeværelse i styret tilfører oss innsikt og perspektiver som reflekterer den nye generasjonen. Med sin kombinasjon av teknisk kunnskap og økonomisk kompetanse, bidrar han til gode diskusjoner om fremtidige markeder og muligheter. 
                  </Card.Text>
                    <a
                    className="card-link"
                    href="https://www.linkedin.com/in/lasse-neeraas-7b2961208/"
                  > 
                      <FiLinkedin/> 
                  </a>
                </Card.Body>
              </Card>
              </Col>
           
          </Row>
        </IconContext.Provider>
      </Container>
    </>
  );
};

export default Portfolio;
