import React from "react";

// React Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// React Scroll
import { Element } from "react-scroll";

// Images
import Aktiv from "../assets/images/aktiv.png";


const About = (props) => {
  return (
    <>
      <Element name="omoss"></Element>

      <Container className="about_container">
        <Row>
          {" "}
           <Col md={6}>
            <div md={6} className="cube_col">
              <div className="stage-cube-cont">
                <div className="cubespinner">
                  <div className="face1">
                    <img src={Aktiv} />
                  </div>
                  <div className="face2">
                    <img src={Aktiv} />
                  </div>
                  <div className="face3">
                    <img src={Aktiv} />
                  </div>
                  <div className="face4">
                    <img src={Aktiv} />
                  </div>
                  <div className="face5">
                    <img src={Aktiv} />
                  </div>
                  <div className="face6">
                    <img src={Aktiv} />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col  md={6}>
              <h1>Om Aktiv Vekst</h1>
            <p className="description_text">
            Aktiv Vekst AS er et familiedrevet investeringsselskap med søkelys på teknologi, livsstil og bærekraftige selskaper (ESG).
            </p>
            <p>
              Vi prioriterer investeringer i norske tidlig fase selskaper og internasjonalisering av norske selskaper i tidlig vekst fase.
              <br />
              Innenfor disse områdene har vi lang erfaring med en rekke ny-etableringer samt ekspansjon ut i det globale markedet. Det å være en aktiv eier mener vi gir gode synergier, ikke bare i en oppstartsfase, men også det faktum at å bygge verdier er en langsiktig og krevende prosess som fordrer et konstruktivt samarbeid mellom eiere og selskapet.
            </p>{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
