import React from "react";

// React Bootstrap
import Image from "react-bootstrap/Image";

// React Scroll
import { Element } from "react-scroll";

// Lottie
import LottieAnimation from "../components/Lottie";
import home from "../assets/animation/lottie.json";

// Image
import Office from "../assets/images/Office.jpg";

const Header = (props) => {
  return (
    <>
      <Element name="forside"></Element>
      <section className="header-image">
        <Image className="img" src={Office} fluid />;
        <div className="description-paragraph">
  <h1>Investering i fremtiden</h1>
      <p>
Aktiv Vekst AS er et familiedrevet investeringsselskap med søkelys på teknologi, livsstil og bærekraftige selskaper (ESG). Innenfor disse områdene har vi lang erfaring med en rekke ny-etableringer samt ekspansjon ut i det globale markedet.
      </p>
        </div>
    
         
 <div className="lottie_animation">
            <LottieAnimation lotti={home} height={200} width={200} />
          </div>
    </section>
    
   
     
  
     
    </>
  );
};

export default Header;
